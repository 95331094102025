@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.header {
  font-family: "KosugiMaru", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  height: 80px;
  padding: 0 32px;
  position: relative;
  z-index: 50;
  background: var(--theme-header-background-color);
}
.header__desc {
  min-height: 32px;
  padding-top: 8px;
}
.header__desc p {
  font-size: 12px;
  font-weight: bold;
  display: inline;
}
.header__desc p::after {
  content: "|";
  padding-left: 4px;
  margin-right: 4px;
  display: inline;
}
.header__desc h1, .header__desc div {
  font-size: 12px;
  font-weight: normal;
  display: inline;
}
.header__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header__main__logo {
  flex-shrink: 0;
  display: block;
}
.header__main__logo img {
  height: 32px;
}
.header__lang {
  width: 128px;
  height: 32px;
  background: var(--theme-primary-color);
  border-radius: 16px;
  padding-left: 16px;
  margin-top: -8px;
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.header__lang img {
  width: 20px;
  height: 20px;
}
.header__lang select {
  width: 100%;
  height: 100%;
  padding-left: 44px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.header__lang option {
  color: #333;
}
.header__login {
  width: 150px;
  height: 32px;
  background: var(--theme-primary-color);
  border-radius: 16px;
  margin-top: -8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}
@media screen and (max-width:768px) {
  .header {
    height: 72px;
    padding: 0 16px;
  }
  .header__desc {
    width: 100%;
    height: 32px;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .header__lang {
    width: 32px;
    margin-top: 0;
    padding-left: 0;
    justify-content: center;
  }
  .header__lang select {
    padding-left: 0;
    opacity: 0;
  }
  .header__login {
    display: none;
  }
}




.c-header__nav {
  display: none;
}
.c-header__nav__list__item {
}
.c-header__nav__list__item__childrenMenu {
  position: relative;
}
.c-header__nav__list__item > a,
.c-header__nav__list__item__childrenMenu > p {
  font-weight: bold;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.c-header__nav__list__item__childrenMenu > p::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin-left: 8px;
}
.c-header__nav__list__item__childrenMenu > ul {
  display: none;
  width: 320px;
  /* max-height: 400px; */
  background: #fff;
  background: var(--theme-header-background-color);
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 21px;
  left: calc(-50% - 64px);
  overflow-y: auto;
}
.c-header__nav__list__item__childrenMenu:hover ul {
  display: block;
}
.c-header__nav__list__item__childrenMenu li {
}
.c-header__nav__list__item__childrenMenu a {
  width: 100%;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.c-header__menu {
  display: none;
}
.c-header__nav__list__item > a:hover,
.c-header__nav__list__item__childrenMenu > p:hover,
.c-header__nav__list__item__childrenMenu a:hover {
  opacity: 0.8;
}
.c-header__fixed {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .c-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .c-header__h1 {
    font-size: 10px;
    background: #fff;
    padding: 4px 8px;
    position: static;
  }
  .c-header__inner {
    height: 56px;
    padding: 0 0 0 8px;
  }
  .c-header__ttl {
    width: auto;
    height: 32px;
    margin-top: 0;
  }
  .c-header__flg {
    display: none;
  }
  .c-header__menu {
    width: 32px;
    height: 32px;
    position: relative;
    display: block;
    margin-left: 8px;
  }
  .c-header__menu::before,
  .c-header__menu::after,
  .c-header__menu > span {
    width: 24px;
    height: 2px;
    background: #333;
    position: absolute;
    left: 4px;
  }
  .c-header__menu::before {
    content: '';
    top: calc(50% - 8px);
    transition: all 0.4s ease;
  }
  .c-header__menu > span {
    top: calc(50% - 1px);
  }
  .c-header__menu::after {
    content: '';
    top: calc(50% + 7px);
    transition: all 0.4s ease;
  }
  .c-header__flg:checked + .c-header__menu::before {
    top: calc(50% - 1px);
    transform: rotate(45deg);
  }
  .c-header__flg:checked + .c-header__menu > span {
    opacity: 0;
  }
  .c-header__flg:checked + .c-header__menu::after {
    top: calc(50% - 1px);
    transform: rotate(-45deg);
  }
  .c-header__flg:checked ~ .c-header__nav {
        /* Fallback for browsers not supporting dvh */
    max-height: calc(100vh - 72px);
    height: 100vh;
    max-height: calc(100dvh - 72px);
    height: 100dvh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-header__nav {
    width: 100%;
    background: #f9f9f9;
    position: absolute;
    top: 72px;
    left: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease;
    display: block;
  }
  .c-header__nav__list {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
    padding-bottom: 64px;
  }
  .c-header__nav__list__item {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
  }
  .c-header__nav__list__item > a {
    border-top: 1px solid #ddd;
  }
  .c-header__nav__list__item a {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    background: #fff;
    position: relative;
  }
  .c-header__nav__list__item a::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ddd;
    border-right: 2px solid #ddd;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 7px);
    right: 8px;
  }
  .c-header__nav__list__item__childrenMenu {
  }
  .c-header__nav__list__item__childrenMenu p {
    line-height: 32px;
    padding: 0 16px;
  }
  .c-header__nav__list__item__childrenMenu p::after {
    display: none;
  }
  .c-header__nav__list__item__childrenMenu p:hover {
    opacity: 1;
  }
  .c-header__nav__list__item__childrenMenu > ul {
    display: block;
    width: 100%;
    max-height: auto;
    border-width: 0;
    box-shadow: none;
    position: static;
    padding: 0;
  }
  .c-header__nav__list__item__childrenMenu li {
    border-top: 1px solid #ddd;
  }
  .c-header__lang {
    margin: 32px auto 160px auto;
  }
}
